<template>
	<div>
	<ContentHeader title="Mapping Wilayah" />
  <div class="content-header">
		<div class="container-fluid">
			<div class="card">
        <div class="card-header">
          <h3 class="card-title">Mapping Wilayah</h3>

          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>
        <div class="card-body" style="display: block;">
          <div class="row">
            <div class="col-2">
              <p v-if="cabang.jenis == 'pusat'">Pilih Cabang</p>
              <el-select v-if="cabang.jenis == 'pusat'" v-model="filter.cabang" filterable placeholder="Pilih Cabang" class="mr-2" style="width: 100%;">
                <el-option
                  v-for="item in data_cabang"
                  :key="item.nama"
                  :label="item.nama"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <p class="mt-4">Pilih Wilayah</p>
          <div class="row mt-n4">
            <div class="col-2">
              <el-select @change="getKota(filter.provinsi)" clearable v-model="filter.provinsi" filterable placeholder="Pilih Provinsi" class="mr-2 mt-4" style="width: 100%;">
                <el-option
                  v-for="item in provinsi"
                  :key="item.nama"
                  :label="item.nama"
                  :value="item.kode"
                >
                </el-option>
              </el-select>
              <br>
              <el-select @change="getKecamatan(filter.kota)" clearable v-model="filter.kota" filterable placeholder="Pilih Kota / Kabupaten" class="mr-2 mt-4" style="width: 100%;">
                <el-option
                  v-for="item in kota"
                  :key="item.nama"
                  :label="item.nama"
                  :value="item.kode"
                >
                </el-option>
              </el-select>
              <br>
              <el-select v-model="filter.kecamatan" clearable filterable placeholder="Pilih Kecamatan" class="mr-2 mt-4" style="width: 100%;">
                <el-option
                  v-for="item in kecamatan"
                  :key="item.nama"
                  :label="item.nama"
                  :value="item.kode"
                >
                </el-option>
              </el-select>
              <br>
              <el-button type="primary" class="mt-5" style="width: 100%;" @click="getMapPasien" :loading="loading"><Search /> Tampilkan</el-button>
            </div>
            <div class="col-1"></div>
            <div class="col-9">
              <p>Data Mapping Pasien</p>
              <el-table :data="dataResult" style="width: 100%" border v-loading="loading">
                <el-table-column prop="nama" label="Wilayah" />
                <el-table-column prop="jumlah_pasien" label="Jumlah Pasien" width="200" />
                <!-- <el-table-column prop="address" label="Address" /> -->
              </el-table>
            </div>
          </div>
        </div>
        <!-- /.card-body -->
        <div class="card-footer" style="display: block;">
        </div>
        <!-- /.card-footer-->
      </div>
		</div>
	</div>
  </div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader'
import { Search } from '@element-plus/icons-vue'

import { ref, onMounted, computed } from 'vue'
import store from '@/store'
import axios from 'axios'
import { ElMessageBox } from 'element-plus'


const user = computed(() => store.getters['auth/user'])
const cabang = computed(() => store.getters['auth/cabang'])
const loading = ref(false)

const filter = ref({
  cabang: null,
  provinsi: null,
  kota: null,
  kecamatan: null
})

// Get Provinsi
const provinsi = ref(null)

const getProvinsi = async () => {
  kota.value = null
  kecamatan.value = null

  let response = await axios.get('api/wilayah/provinsi')

  provinsi.value = response.data
}

// End Get Provinsi

// Get Kota
const kota = ref(null)

const getKota = async (kode) => {
  kecamatan.value = null

  let response = await axios.get(`api/wilayah/kota/${kode}`)
  kota.value = response.data
}
// End Get Kota

// Get kecamatan
const kecamatan = ref(null)

const getKecamatan = async (kode) => {
  let response = await axios.get(`api/wilayah/kecamatan/${kode}`)
  kecamatan.value = response.data
}
// End Get kecamatan

// Get Data Mapping
const dataResult = ref(null)
const getMapPasien = async () => {
  loading.value = true
  if(filter.value.provinsi == null) {
    loading.value = false
    return ElMessageBox.alert('Pilih Wilayah Terlebih Dahulu! Minimal Provinsi', 'Informasi', {
      confirmButtonText: 'OK',
    })
  } else {
    let jenis_wilayah = null
    let kode = null
    let cabang_id = null
    if(cabang.value.jenis == 'pusat') {
      if(filter.value.cabang == null) {
        return ElMessageBox.alert('Pilih Cabang', 'Informasi', {
          confirmButtonText: 'OK',
        })
      } else {
        cabang_id = filter.value.cabang
        console.log(cabang_id)
      }
    } else {
      cabang_id = user.value.cabang_id
    }

    console.log(filter.value)
    if (filter.value.kecamatan != null && filter.value.kecamatan != '') {
      jenis_wilayah = 'kecamatan'
      kode = filter.value.kecamatan
    } else if (filter.value.kota != null && filter.value.kota != '') {
      jenis_wilayah = 'kota'
      kode = filter.value.kota
    } else {
      jenis_wilayah = 'provinsi'
      kode = filter.value.provinsi
    }
    
    console.log(cabang.id)
    let { data } = await axios.get(`api/pasien/getMapPasien/${cabang_id}/${jenis_wilayah}/${kode}`)

    if(data != 'kosong') {
      dataResult.value = data
    }

    loading.value = false
  }
}
// End Get Data Mapping

// Get Cabang
const data_cabang = ref(null)
const  getCabang = async () => {
  let { data } = await axios.get(`api/cabang/getWhereCabang/${cabang.value.id}`)

  if(data != 'kosong') {
    data_cabang.value = data
  }
}
// End Get Cabang

onMounted(() => {		    	
  getProvinsi()
  getCabang()
})
</script>